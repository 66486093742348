<template>
  <div class="container ma-tb-20">
  	<car-state></car-state>
		<div class="bg-fff setting-index">
			<back-button msg="系统设置"></back-button>
			<div class="setting-btn flex">
				<router-link :to="{name: 'settingAccount'}">
					<div class="btn btn-58AFDA flex f-center f-jcenter flex-direction ma-l-10">
						<div class="iconfont icon-xingzhuang fon-24 c-fff"></div>
						<div class="setting-name c-fff">帐号信息</div>
					</div>
				</router-link>
				<router-link :to="{name: 'settingParameter'}">
					<div class="btn btn-587ADA flex f-center f-jcenter flex-direction ma-l-10">
						<div class="iconfont icon-tubiao01 fon-24 c-fff"></div>
						<div class="setting-name c-fff">系统参数</div>
					</div>
				</router-link>
				<div class="btn btn-da6d58 flex f-center f-jcenter flex-direction ma-l-10" @click="show = true">
					<div class="iconfont icon-shuaxin fon-24 c-fff"></div>
					<div class="setting-name c-fff">刷新设置</div>
				</div>
				<div class="btn btn-DA5899 flex f-center f-jcenter flex-direction ma-l-10" @click="logout">
					<div class="iconfont icon-tuichu fon-24 c-fff"></div>
					<div class="setting-name c-fff">退出登录</div>
				</div>
			</div>
		</div>
		<van-popup z-index="2000" v-model:show="show" round close-on-click-overlay="false">
			<div class="popup-box flex flex-direction f-center">
				<el-select class="width100 ma-b-20" v-model="value" placeholder="请选择刷新时间">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<div class="width70 flex f-center ma-lr-auto">
					<van-button @click="setRefreshTime" color="#58AFDA" class="width100" type="primary">确认</van-button>
				</div>
			</div>
		</van-popup>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
let _this;
export default {
  data () {
    return {
			show: false,
			options: [{
				value: '0',
				label: '不刷新'
			}, {
				value: '10',
				label: '10秒刷新'
			}, {
				value: '30',
				label: '30秒刷新'
			}, {
				value: '60',
				label: '1分钟刷新'
			}, {
				value: '180',
				label: '3分钟刷新'
			}],
			value: ''
    }
  },
  created(){
    _this = this;
		let time = localStorage.getItem('refresh_time')
		if (!_this.$validator.isEmpty(time)) {
			_this.value = time
		}
  },
  methods:{
		...mapActions(['SetRefreshTime']),
		// 退出登录
    logout(){
      _this.$dialog.confirm({
        title: '提示',
        message: '您确认要退出登录吗？',
      }).then(() => {
        _this.$store.dispatch('logout');
      })
      .catch(() => {
        
      });
    },

		// 设置刷新时间
		setRefreshTime() {
			_this.SetRefreshTime(_this.value)
      .then((res) => _this.$toast.success({
        message: '设置成功',
        duration: '1500',
        onClose() {
          _this.show = false
        }
      }))
      .catch(err => console.log(err) )
		}
  }
}
</script>

<style lang="less" scoped>
	.title{
		margin-left: 0;
	}
	.setting-btn{
		padding: 15px 0 0 68px;
		.btn{
			width: 120px;
			height: 120px;
			box-shadow: 5px 10px 32px 0px rgba(42, 56, 91, 0.06);
			border-radius: 8px;
			border: 1px solid #EEEEEE;
			.setting-name{
				margin-top: 20px;
				font-size: 20px;
			}
		}
		.btn-58AFDA{
			background-color: #58AFDA;
		}
		.btn-587ADA{
			background-color: #587ADA;
		}
		.btn-DA5899{
			background-color: #DA5899;
		}
		.btn-da6d58{
			background-color: #da6d58;
		}
	}
	.popup-box{
		width: 400px;
		padding: 20px;
	}
</style>